import axios from "axios";

const local = "http://localhost:5000/api/v1/contact/create";
const dev =
	"https://methodical-website-api.redblacktree.net/api/v1/contact/create";
const prod = "https://marketing-api.methodical.ai/api/v1/contact/create";

export const createContact = async (data) => {
	try {
		let resp = await axios.post(dev, data);
		return resp;
	} catch (e) {
		return e;
	}
};
