import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import "../Pricing/pricing.css";
import $ from "jquery";
// import "rsuite/dist/rsuite.min.css";
import { Footer } from "../Parallax/footer";
import Logo from "../../images/methodical-logo.svg";

const Features = (props) => {
	$(window).on("scroll", function () {
		if ($(window).width() > 767) {
			if ($(this).scrollTop() > 760) {
				// Set position from top to add class
				$("#custom_header").addClass("bg-transparent");
				$(".headerNav h4").addClass("text-white");
				$(".slider-social a").addClass("text-white");
			} else {
				$("#custom_header").removeClass("bg-transparent");
				$(".headerNav h4").removeClass("text-white");
				$(".slider-social a").removeClass("text-white");
			}
		} else if ($(window).width() <= 767) {
			if ($(this).scrollTop() > 1990) {
				$(".broad").css("background-color", "#041F60");
				$(".navbar").css("background-color", "#041F60");
				$(".my_nav_tog span").css("background-color", "black");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: black; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: black; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "black");
				$(".social-icons span a").css("color", "black");
				$(".broad .rites-res span").css("color", "black");
			} else {
				$(".broad").css("background-color", "white");
				$(".navbar").css("background-color", "white");
				$(".my_nav_tog span").css("background-color", "black");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: black; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: black; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "black");
				$(".social-icons span a").css("color", "black");
				$(".broad .rites-res span").css("color", "black");
			}
		}
	});

	return (
		<div className="head">
			<Header />
			<section id="price" className="price text-center">
				<div className="container pricing">
					<div className="row mb-3">
						<div className="col-sm-12">
							{/*Heading*/}
							<div className="title mb-5">
								<h5 className="mb-2">Select the best available packages</h5>
								<h2>Simple, Affordable Pricing </h2>
							</div>
						</div>
					</div>

					<div className="row wow fadeInUp">
						<div className="col-lg-3 col-md-12 mb-lg-0 mb-4">
							<div className="price-item">
								<h2 className="font-weight-bold alt-font mb-1">
									₹400 <span className="period">/ month</span>{" "}
									<span className="period"> / user</span>
								</h2>
								<h5 className="mb-3 alt-font font-weight-bold alt-color">
									Basic Package
								</h5>
								<p className="description">
									Simple informatino website suiteable for any startup or
									personal brand
								</p>
								<ul className="p-0 price-list green list-unstyled">
									<li>
										<i className="fas fa-check" />
										Up to 10 users
									</li>
									<li>
										<i className="fas fa-check" />3 Free apps
									</li>
								</ul>
								<a
									href="javascript:void(0)"
									className="btn btn-large btn-green d-block mt-3 mx-auto w-100"
								>
									Get it now
								</a>
							</div>
						</div>
						<div className="col-lg-3 col-md-12 mb-0">
							<div className="price-item">
								<h2 className="font-weight-bold alt-font mb-1">
									₹600 <span className="period">/ month</span>{" "}
									<span className="period"> / user</span>
								</h2>
								<h5 className="mb-3 alt-font font-weight-bold main-color">
									Professional Package
								</h5>
								<p className="description">
									Simple informatino website suiteable for any startup or
									personal brand
								</p>
								<ul className="p-0 price-list blue list-unstyled">
									<li>
										<i className="fas fa-check" />
										Up to 100 users
									</li>
									<li>
										<i className="fas fa-check" />8 Free apps
									</li>
								</ul>
								<a
									href="javascript:void(0)"
									className="btn btn-large btn-primary d-block mt-3 mx-auto w-100"
								>
									Get it now
								</a>
							</div>
						</div>
						<div className="col-lg-3 col-md-12 mb-lg-0 mb-4">
							<div className="price-item">
								<h2 className="font-weight-bold alt-font mb-1">
									₹800 <span className="period">/ month</span>{" "}
									<span className="period"> / user</span>
								</h2>
								<h5 className="mb-3 alt-font font-weight-bold alt-color">
									Enterprise Package
								</h5>
								<p className="description">
									Simple informatino website suiteable for any startup or
									personal brand
								</p>
								<ul className="p-0 price-list green list-unstyled">
									<li>
										<i className="fas fa-check" />
										Up to 500 users
									</li>
									<li>
										<i className="fas fa-check" />
										15 Free apps
									</li>
								</ul>
								<a
									href="javascript:void(0)"
									className="btn btn-large btn-green d-block mt-3 mx-auto w-100"
								>
									Get it now
								</a>
							</div>
						</div>
						<div className="col-lg-3 col-md-12 mb-0">
							<div className="price-item">
								<h2 className="font-weight-bold alt-font mb-1 pt-5 pb-4">
									{/* $199 <span className="period">/ month</span> */}
								</h2>
								<h5 className="mb-3 alt-font font-weight-bold main-color pt-3">
									Custom Package
								</h5>
								<p className="description">
									Simple informatino website suiteable for any startup or
									personal brand
								</p>
								<ul className="p-0 price-list blue list-unstyled">
									<li>
										<i className="fas fa-check" />
										Over 500 users
									</li>
									<li>
										<i className="fas fa-check" />
										Talk to us for pricing
									</li>
								</ul>
								<a
									href="javascript:void(0)"
									className="btn btn-large btn-outline-primary d-block mt-3 mx-auto w-100"
								>
									Contact us
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <div className="container">
					<Footer />
				</div> */}
			<section className="section footer" id="p-footer">
				<div
					className="bg-overlay opacity-9"
					style={{ background: "#041F60" }}
				/>
				<div className="container expand-container ">
					<div className="row align-items-center mb-3 mb-md-5 section-heading">
						<div
							className="col-12 col-md-12 section8left wow px-2 slideInLeft"
							data-wow-delay=".8s"
						>
							<h2 className="main-font">
								One platform for all your workflows.{" "}
								<span className="d-block">
									Join a growing community of Methodical users.
								</span>
							</h2>
						</div>
					</div>
					<div className="row ">
						<div className="col-12">
							<h5 className="main-font text-white mt-5">Useful Links</h5>
						</div>
					</div>
					<Footer />
					<div
						className="box position-relative footer-zoom6In wow"
						data-wow-delay=".5s"
					>
						<div className="box-overlay" />
						<div className="row align-items-center">
							<div className="col-12 col-md-7 col-lg-9">
								<h2 className="main-font">Start using Methodical for free</h2>
								<p className="py-4 py-md-0">
									Access all Methodical features for 30 days, then decide which
									plan best suits your business or compare plans.{" "}
								</p>
							</div>
							<div className="col-12 col-md-5 col-lg-3 text-center">
								<a
									href="#home-banner"
									className="btn btn-medium btn-rounded text-capitalize mb-4 mb-md-0 getStarted"
								>
									Learn more
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Features;
