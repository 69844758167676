import ParallaxComponent from "./Components/Parallax/ParallaxComponent";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Pricing from "./Components/Pricing/Pricing";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy.jsx";
import Features from "./Components/Features/Features.jsx";
function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<ParallaxComponent />} />
					<Route path="/features" element={<Features />} />
					<Route path="/pricing" element={<Pricing />} />
					<Route path="/privacyPolicy" element={<PrivacyPolicy />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
