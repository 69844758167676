import React, { useState } from "react";
import $ from "jquery";
import { useEffect } from "react";
import Logo from "../../images/methodical-logo.svg";
// import SliderImg from "../../images/agency-hotspot/images/slider-img.png";
import SliderImg from "../../images/36.png";
import HRImg from "../../images/hr2.jpg";
import marketingImg from "../../images/marketing.jpg";
import salesImg from "../../images/sales1.jpg";
import accountImg from "../../images/accounting.jpg";
import operations from "../../images/operations.jpg";
import IT from "../../images/IT.jpg";
import workFlow from "../../images/workflow.png";
import ai from "../../images/microchip.png";
import integration from "../../images/distributed.png";
import dataModels from "../../images/predictive.png";
import spreadSheets from "../../images/spreadsheet.png";
import dataApi from "../../images/api.png";
import analytics from "../../images/analysis.png";
import dataImg from "../../images/data-collection.png";
import searchIcon from "../../images/seo.png";
import m365 from "../../images/Microsoft-white 2.png";
import gSuite from "../../images/Gsuite 3.png";
import xero from "../../images/Xero2.png";
import zoho from "../../images/zoho1.png";
import { Footer } from "./footer";
import banner1video from "../../videos/pexels-kelly-lacy-6595371 (1440p).mp4";
import { createContact } from "../../api";
// import banner1video from "../../videos/slide1.mov";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import methodicalImg from "../../images/methodical-img.png";
import background from "../../images/apple_macbook_air_silver.png";
const ParallaxComponent = (props) => {
	const [isNavExpanded, setIsNavExpanded] = useState(false);
	const [isMobNavExpanded, setIsMobNavExpanded] = useState(false);
	const [slide3Img, setSlide3Img] = useState(HRImg);
	const [error, setError] = useState("");
	const [respSuccess, setRespSuccess] = useState(false);
	const [activeCard, setActiveCard] = useState(0);
	const [data, setData] = useState({
		name: "",
		phone: "",
		email: "",
		message: "",
	});
	const menuClick = () => {
		setIsNavExpanded(!isNavExpanded);
	};
	const mobileClick = () => {
		setIsMobNavExpanded(!isMobNavExpanded);
	};
	useEffect(() => {
		$(".side-nav-menu .nav-menu li a").on("click", function () {
			setIsNavExpanded(false);
			$(".side_nav").removeClass("expand_nav");
			$("#close_nav").removeClass("close_nav");
			$(".side_nav").removeClass("expand_nav");
			$("#my_tog").removeClass("close_nav");
			$(".fixed-top").removeClass("h-100");

			$("#close_nav").attr("id", "my_tog");
			$("#pp-nav").show();
			$(".side-nav-menu .nav-menu .nav-item .nav-link").removeClass("active");
			$(this).addClass("active");
		});
		if (isMobNavExpanded) {
			console.log("on;cick");
			$(".fixed-top").addClass("h-100");
			$(".broad").addClass("broad-nav");
			$(".broad").css({ opacity: "1" });
			$(".head-nav").hide();
			$("body").addClass("show-modal");
			$("nav").addClass("w-100");
		} else {
			console.log("broad click");
			$(".fixed-top").removeClass("h-100");

			$(".broad").css({ opacity: "0" });
			$("body").removeClass("show-modal");
			setTimeout(function () {
				$(".broad").removeClass("broad-nav");
			}, 100);
		}
	}, [isMobNavExpanded]);
	useEffect(() => {
		if (isNavExpanded) {
			$("#pp-nav").hide();
			$(".overlay-body").addClass("show_body_overlay");
		} else {
			$(".overlay-body").removeClass("show_body_overlay");
			$("#pp-nav").show();
		}
	}, [isNavExpanded]);
	useEffect(() => {
		if ($(window).width() < 768) {
			$(".slide-1-content").removeClass("flex-nowrap");
			$(".slide-1-content").css("gap", "0");
		}
	}, []);
	const imageChange = (image) => {
		$(".section3Img").addClass("zoomIn");
		setTimeout(function () {
			$(".section3Img").removeClass("zoomIn");
		}, 1600);
		setSlide3Img(image);
	};

	$(window).on("scroll", function () {
		if ($(window).width() <= 767) {
			console.log($(this).scrollTop());
			$(".broad").css("background-color", "#476005");
			$(".navbar").css("background-color", "#476005");
			if ($(this).scrollTop() > 10100) {
				// slide 9
				$(".broad").css("background-color", "#041F60");
				$(".navbar").css("background-color", "#041F60");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 8600) {
				// slide 8
				$(".broad").css("background-color", "#4e002f");
				$(".navbar").css("background-color", "#4e002f");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 7400) {
				// slide 7
				$(".broad").css("background-color", "#056569");
				$(".navbar").css("background-color", "#056569");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 5900) {
				// slide 6
				$(".broad").css("background-color", "rgb(140, 108, 9)");
				$(".navbar").css("background-color", "rgb(140, 108, 9)");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 4300) {
				// slide 5
				$(".broad").css("background-color", "rgb(169, 10, 10)");
				$(".navbar").css("background-color", "rgb(169, 10, 10)");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 3150) {
				// slide 4
				$(".broad").css("background-color", "#0a455c");
				$(".navbar").css("background-color", "#0a455c");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 1420) {
				// slide 3
				$(".broad").css("background-color", "#4d0026");
				$(".navbar").css("background-color", "#4d0026");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 750) {
				// slide 2
				$(".broad").css("background-color", "#96471b");
				$(".navbar").css("background-color", "#96471b");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
				$("#pagepiling").addClass("margin-manage");
			} else {
				// slide 1
				$(".broad").css("background-color", "#476005");
				$(".navbar").css("background-color", "#476005");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").removeClass("fixed-top");
				$("#home").removeClass("fix-top");
				$("#pagepiling").removeClass("margin-manage");
			}
		} else if ($(window).width() <= 1024) {
			if ($(this).scrollTop() > 9300) {
				// slide 9
				$(".broad").css("background-color", "#041F60");
				$(".navbar").css("background-color", "#041F60");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 7900) {
				// slide 8
				$(".broad").css("background-color", "#4e002f");
				$(".navbar").css("background-color", "#4e002f");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 6700) {
				// slide 7
				$(".broad").css("background-color", "#056569");
				$(".navbar").css("background-color", "#056569");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 5200) {
				// slide 6
				$(".broad").css("background-color", "#997300");
				$(".navbar").css("background-color", "#997300");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 4400) {
				// slide 5
				$(".broad").css("background-color", "rgb(121, 7, 7)");
				$(".navbar").css("background-color", "rgb(121, 7, 7)");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 3300) {
				// slide 4
				$(".broad").css("background-color", "#0a455c");
				$(".navbar").css("background-color", "#0a455c");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 1650) {
				// slide 3
				$(".broad").css("background-color", "#4d0026");
				$(".navbar").css("background-color", "#4d0026");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
			} else if ($(this).scrollTop() > 750) {
				// Slide 2
				$(".broad").css("background-color", "#96471b");
				$(".navbar").css("background-color", "#96471b");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
				$("#home").addClass("fixed-top");
				$("#home").addClass("fix-top");
				$("#pagepiling").addClass("margin-manage");
			} else {
				$(".side_nav").css("background-color", "#476005");
				$(".navbar").css("background-color", "#fff0");
				$(".my_nav_tog span").css("background-color", "black");
				$(".broad .side-nav-menu ul li a").css("color", "black");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: black; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: black; } </style>"
				);
				$(".social-icons span a").css("color", "black");
				$(".broad .rites-res span").css("color", "black");
			}
		} else {
			$("#home").removeClass("fixed-top");
			$("#home").removeClass("fix-top");
		}
	});

	const handleChange = (e) => {
		if (e.target.name === "userName") {
			setData({
				...data,
				name: e.target.value,
			});
		}
		if (e.target.name === "userPhone") {
			setData({
				...data,
				phone: e.target.value,
			});
		}
		if (e.target.name === "userEmail") {
			setData({
				...data,
				email: e.target.value,
			});
		}
		if (e.target.name === "userMessage") {
			setData({
				...data,
				message: e.target.value,
			});
		}
	};
	const handleSubmit = async () => {
		console.log(data);
		if ((data.name, data.phone, data.email, data.message)) {
			const resp = await createContact(data);
			console.log(resp);
			if (resp.status == 200) {
				setData({
					name: "",
					email: "",
					message: "",
					phone: "",
				});
				toast.success("Thank you! We will get back to you shortly.");
			}
		} else {
			setError("Please fill all the fields*");
		}
	};
	const handleCardClick = (index) => {
		setActiveCard(index);
	};
	const renderCard = (index, title, image) => (
		<div
			key={index}
			className={`card text-dark ${
				activeCard === index ? "active" : ""
			} wow slideCard`}
			data-wow-delay={`.${index + 2}s`}
			onClick={() => {
				handleCardClick(index);
				imageChange(image);
			}}
		>
			<div className="card-header" role="tab" id={`heading${index}`}>
				<h5 className="mb-0">
					<a
						data-toggle="collapse"
						href={`#collapse${index}`}
						aria-expanded={activeCard === index}
						aria-controls={`collapse${index}`}
					>
						{title}
					</a>
				</h5>
			</div>
			{/* ... (your card content) */}
		</div>
	);
	return (
		<div>
			{/* Preloader */}
			{/* <div className="loader" id="loader-fade">
				<div className="linear-activity">
					<div className="indeterminate" />
				</div>
			</div> */}
			{/* Preloader End */}
			{/* START HEADER */}
			<div className="wrap_container cursor-light">
				<div className="main_container">
					{/* <div className={isNavExpanded ? `side_nav expand_nav` : "side_nav"}>
						<div className="my-sticky-nav">
							<div className="side_menu">
								<a className="brand-logo link" href="index-agency-hotspot.html">
									<img src={Logo} alt="logo" style={{ width: "50px" }} />
								</a>
								<div
									id="my_tog"
									className={
										isNavExpanded
											? "my_tog link close_nav zIndex"
											: "my_tog link cursor-light zIndex"
									}
									
									onClick={menuClick}
								>
									<span />
									<span />
									<span />
								</div>
								<div>
									<a
										className="brand-logo sticky-logo link"
										href="index-agency-hotspot.html"
									>
										<img
											src={Logo}
											alt="logo"
											style={{
												width: "50px",
												display: "block",
												marginLeft: "10px",
												marginRight: "20px",
											}}
										/>
									</a>
								</div>
								<nav className="side-nav-menu">
									<ul className="nav-menu nav-fill" id="menu">
										<li className="nav-item">
											<a
												data-menuanchor="home-banner"
												className="nav-link link active line"
												href="#home-banner"
											>
												Home{" "}
											</a>
										</li>
										<li className="nav-item">
											<a
												data-menuanchor="product-overview"
												className="nav-link link line"
												href="#product-overview"
											>
												Product Overview
											</a>
										</li>
										<li className="nav-item">
											<a
												data-menuanchor="about"
												className="nav-link link line"
												href="#about"
											>
												Product Features
											</a>
										</li>
										<li className="nav-item">
											<a
												data-menuanchor="blog"
												className="nav-link link line"
												href="#blog"
											>
												Workflows
											</a>
										</li>
										<li className="nav-item">
											<a
												data-menuanchor="portfolio"
												className="nav-link link line"
												href="#portfolio"
											>
												Data
											</a>
										</li>
										<li className="nav-item">
											<a
												data-menuanchor="team"
												className="nav-link link line"
												href="#team"
											>
												AI
											</a>
										</li>
										<li className="nav-item">
											<a
												data-menuanchor="integrations"
												className="nav-link link line"
												href="#integrations"
											>
												Integrations
											</a>
										</li>
										<li className="nav-item">
											<a
												data-menuanchor="contact"
												className="nav-link link line"
												href="#contact"
											>
												Contact
											</a>
										</li>
										<li className="nav-item">
											<a
												data-menuanchor="footer"
												className="nav-link link line"
												href="#footer"
											>
												Links
											</a>
										</li>
									</ul>
									<div
										className={
											isNavExpanded
												? "slider-social side-icons op-1"
												: "slider-social side-icons op-0"
										}
									>
										<ul className="list-unstyled">
											<li className="animated-wrap">
												<a
													className="animated-element"
													href="javascript:void(0);"
												>
													<i className="fab fa-facebook-f" />
												</a>
											</li>
											<li className="animated-wrap">
												<a
													className="animated-element"
													href="javascript:void(0);"
												>
													<i className="fab fa-twitter" />
												</a>
											</li>
											<li className="animated-wrap">
												<a
													className="animated-element"
													href="javascript:void(0);"
												>
													<i className="fab fa-linkedin-in" />
												</a>
											</li>
											<li className="animated-wrap">
												<a
													className="animated-element"
													href="javascript:void(0);"
												>
													<i className="fab fa-instagram" />
												</a>
											</li>
										</ul>
									</div>
									<p
										className={
											isNavExpanded
												? "rites-res link op-1"
												: "rites-res link op-0"
										}
									>
										<span style={{ color: "#ffffff", fontSize: "10px" }}>
											© RedBlackTree Technologies Pvt. Ltd.
										</span>
									</p>
								</nav>
							</div>
						</div>
					</div> */}
					{/* Nav-Bar */}
					<header id="home">
						<nav className={"navbar navbar-light cursor-light d-md-none"}>
							<div
								className="my_nav_tog d-block d-md-none"
								onClick={mobileClick}
							>
								<span />
								<span />
								<span />
							</div>
							<a className="navbar-brand ml-auto" href="/">
								<img src={Logo} alt="Logo" style={{ width: "30px" }} />
							</a>
						</nav>
						<div className="broad">
							<span
								className="btn-close"
								id="btn_sideNavClose"
								onClick={mobileClick}
							>
								<i />
								<i />
							</span>
							<nav className="side-nav-menu">
								<a className="brand-logo" href="/">
									<img src={Logo} alt="Logo" style={{ width: "50px" }} />
								</a>
								<ul className="nav-menu nav-fill">
									{/* <li className="nav-item">
										<a
											className="nav-link"
											href="/"
											// onClick={mobileClick}
										>
											Features
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											href="/pricing"
											// onClick={mobileClick}
										>
											Pricing
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="/">
											About Us
										</a>
									</li> */}
									<li className="nav-item">
										<a
											className="nav-link"
											href="https://app.methodical.ai/login"
										>
											Sign In
										</a>
									</li>
									{/* <li className="nav-item">
										<a className="nav-link" href="#blog" onClick={mobileClick}>
											Workflows
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											href="#portfolio"
											onClick={mobileClick}
										>
											Data
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#team" onClick={mobileClick}>
											AI
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											href="#integrations"
											onClick={mobileClick}
										>
											Integrations
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											href="#contact"
											onClick={mobileClick}
										>
											Contact
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											href="#footer"
											onClick={mobileClick}
										>
											Links
										</a>
									</li> */}
								</ul>
								<div className="social-icons">
									<span>
										<a href="javascript:void(0);">
											<i className="fab fa-facebook-f" />
										</a>
									</span>
									<span>
										<a href="javascript:void(0);">
											<i className="fab fa-twitter" />
										</a>
									</span>
									<span>
										<a href="javascript:void(0);">
											<i className="fab fa-linkedin-in" />
										</a>
									</span>
									<span>
										<a href="javascript:void(0);">
											<i className="fab fa-instagram" />
										</a>
									</span>
								</div>
								<p className="rites-res">
									<a
										href="/privacyPolicy"
										target="_blank"
										className="mb-4 privacy-link"
									>
										Privacy Policy
									</a>
									<span style={{ color: "#000000", fontSize: "14px" }}>
										© RedBlackTree Technologies Pvt. Ltd.
									</span>
								</p>
							</nav>
						</div>
					</header>
				</div>
			</div>
			{/* END HEADER */}
			<div id="pagepiling">
				{/* START BANNER */}
				<section className="section slide1 p-0" id="home-banner">
					<div
						className="slider-area"
						id="slider-area"
						style={{ height: window.innerHeight }}
					>
						<video src={banner1video} autoPlay muted loop playsInline />
						<div className="bg-overlay bg-green opacity-8" />

						<div className="container pt-0">
							<div
								className="row align-items-center justify-content-center flex-nowrap slide-1-content"
								style={{ gap: "100px" }}
							>
								<div className="col-12 col-md-12 text-center">
									{/* <div className="logo-header">
										<img src={Logo} alt="Logo" width={70} height={70} />
										<h4>Methodical</h4>
									</div> */}

									<h1 className="main-font font-weight-bold text-white ">
										Workflow, Automation and AI
										{/* <span className="d-block">Agency in NY.</span> */}
									</h1>
									<p className="alt-font text-white my-4">
										An Operating System for the modern digital enterprise with
										everything you need to automate enterprise processes.{" "}
									</p>
									<div className="btns">
										<a
											href="#about"
											className="btn btn-medium btn-rounded  text-capitalize mb-4 mb-md-0 getStarted"
										>
											Get Started
										</a>
										<a
											href="#contact"
											className="btn btn-medium btn-rounded text-capitalize mb-4 mb-md-0 btn-reqDemo"
										>
											Request Demo
										</a>
									</div>
								</div>
								{/* <div
									className="col-12 col-md-6 section1right wow"
									data-wow-delay=".8s"
								>
									<div className="banner-image">
										<svg
											className="slider-shape"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 700 700"
											width={"100%"}
											height={"100%"}
										>
											<g>
												<path
													data-depth="0.05"
													id="shape-1"
													fill="#ffffff"
													d="M150,100 h400 a40,40 0 0 1 40,40 v400 a40,40 0 0 1 -40,40 h-400 a40,40 0 0 1 -40,-40 v-400 a40,40 0 0 1 40,-40 z"
													opacity=".25"
												/>
												<path
													data-depth="0.07"
													id="shape-2"
													fill="#ffffff"
													d="M 120 70 h440 a 60 60 0 0 1 60 60 v 420 a 60 60 0 0 1 -60 60 h -420 a 60 60 0 0 1 -60 -60 v -420 a 60 60 0 0 1 60 -60 Z"
													opacity=".25"
													stroke="#ffffff"
													strokeWidth="20"
												/>
												<path
													data-depth="0.09"
													id="shape-3"
													fill="#ffffff"
													d="M150,50 h400 a40,40 0 0 1 40,40 v400 a40,40 0 0 1 -40,40 h-400 a40,40 0 0 1 -40,-40 v-400 a40,40 0 0 1 40,-40 z"
													opacity=".25"
													transform="rotate(30, 300, 300)"
												/>
												<path
													data-depth="0.09"
													id="shape-4"
													fill="#ffffff"
													d="M150,50 h400 a40,40 0 0 1 40,40 v400 a40,40 0 0 1 -40,40 h-400 a40,40 0 0 1 -40,-40 v-400 a40,40 0 0 1 40,-40 z"
													opacity=".25"
													transform="rotate(60, 300, 300)"
												/>
												<clipPath id="shape-img">
													<use
														xlinkHref="#shape-1"
														style={{ overflow: "visible" }}
													/>
												</clipPath>
											</g>
											<image
												data-depth="0.1"
												clipPath="url(#shape-img)"
												height="100%"
												width="100%"
												xlinkHref={SliderImg}
											/>
										</svg>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</section>
				{/* END BANNER */}
				{/* Slide 2 */}

				<section
					className="section slide4 portfolio-bg product-overview"
					id="product-overview"
				>
					<div
						className="bg-overlay opacity-9"
						style={{ background: "rgb(193 92 37)" }}
					/>
					<div className="container">
						<div className="row align-items-center section-heading mb-0">
							<div
								className="col-12 col-md-12 main-heading section2fade wow"
								data-wow-delay=".8s"
							>
								<h2 className="main-font">
									An all-in-one platform to build software for all your
									enterprise needs.{" "}
								</h2>
								<span className="d-block mb-3">
									Automate process, build apps, integrate workflows with
									enterprise systems, build custom analytics and much more...
								</span>
							</div>
							{/* <div
								className="col-12 col-md-6 section4right wow"
								data-wow-delay=".8s"
							>
								<div className="row my-4 my-md-0">
									<div className="col-12 col-md-4">
										<div className="parallax-box text-white">
											<h2 className="portfolio-counter">456</h2>
											<h5>Happy Clients</h5>
										</div>
									</div>
									<div className="col-12 col-md-4">
										<div className="parallax-box text-white">
											<h2 className="portfolio-counter">599</h2>
											<h5>Finished Projects</h5>
										</div>
									</div>
									<div className="col-12 col-md-4">
										<div className="parallax-box text-white">
											<h2 className="portfolio-counter">780</h2>
											<h5>Hours Worked</h5>
										</div>
									</div>
								</div>
							</div> */}
						</div>
						{/* Portfolio */}
						<div className="row portfolio-fade wow" data-wow-delay=".8s">
							<div className="col-sm-12">
								<div className="d-flex align-items-center justify-content-center">
									{/* <div className="single-work"> */}
									{/* <iframe
										width="560"
										height="500"
										src="https://www.youtube.com/embed/IqXNhakuwVc"
										allowFullScreen
									></iframe> */}
									<iframe
										width="80%"
										height="510"
										src="https://www.youtube.com/embed/fFSkuLFBLSc"
										title="What is  Methodical?"
										frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowfullscreen
										className="yt"
									></iframe>
									{/* <div className="image-container p-3">
										<div className="methodical-img">
											<img src={methodicalImg} alt="" />
										</div>
										<img src={background} alt="" className="mock-area" />
									</div> */}
									{/* </div> */}
									{/* Item 1 */}
									{/* <div className="item">
										<div className="team-data-img">
											<a href={portfolioImg4} data-fancybox="portfolio-images">
												<div className="single-work">
													<img
														src={portfolioImg1}
														alt="team"
														className="img-responsive"
														data-no-retina=""
													/>
													<div className="overlay-text center-block">
														<div className="cases-image-inner">
															<span className="cases-line top" />
															<span className="cases-line top-right" />
															<span className="cases-line bottom" />
															<span className="cases-line bottom-left" />
															<div className="image-overlay">
																<div className="search-icon">
																	<i className="fa fa-search" />
																</div>
																<h6 className="text-white text-uppercase alt-font">
																	View Project
																</h6>
															</div>
														</div>
													</div>
												</div>
											</a>
											<div className="portfolio-content">
												<h5 className="text-yellow main-font mb-2 text-uppercase">
													Website for Clothing Brand
												</h5>
											</div>
										</div>
									</div> */}
									{/* Item 2 */}
									{/* <div className="item">
										<div className="team-data-img">
											
										</div>
									</div> */}
									{/* Item 3 */}
									{/* <div className="item">
										<div className="team-data-img">
											<a href={portfolioImg4} data-fancybox="portfolio-images">
												<div className="single-work">
													<img
														src={portfolioImg3}
														alt="team"
														className="img-responsive"
														data-no-retina=""
													/>
													<div className="overlay-text center-block">
														<div className="cases-image-inner">
															<span className="cases-line top" />
															<span className="cases-line top-right" />
															<span className="cases-line bottom" />
															<span className="cases-line bottom-left" />
															<div className="image-overlay">
																<div className="search-icon">
																	<i className="fa fa-plus" />
																</div>
																<h6 className="text-white text-uppercase alt-font">
																	Show Image
																</h6>
															</div>
														</div>
													</div>
												</div>
											</a>
											<div className="portfolio-content">
												<h5 className="text-yellow main-font mb-2 text-uppercase">
													Website for Digital Services
												</h5>
											</div>
										</div>
									</div> */}
									{/* Item 4 */}
									{/* <div className="item">
										<div className="team-data-img">
											<a href={portfolioImg4} data-fancybox="portfolio-images">
												<div className="single-work">
													<img
														src={portfolioImg4}
														alt="team"
														className="img-responsive"
														data-no-retina=""
													/>
													<div className="overlay-text center-block">
														<div className="cases-image-inner">
															<span className="cases-line top" />
															<span className="cases-line top-right" />
															<span className="cases-line bottom" />
															<span className="cases-line bottom-left" />
															<div className="image-overlay">
																<div className="search-icon">
																	<i className="fa fa-search" />
																</div>
																<h6 className="text-white text-uppercase alt-font">
																	View Latest Work
																</h6>
															</div>
														</div>
													</div>
												</div>
											</a>
											<div className="portfolio-content">
												<h5 className="text-yellow main-font mb-2 text-uppercase">
													Website for SEO Marketing
												</h5>
											</div>
										</div>
									</div> */}
								</div>
								{/* <a className="circle ini-customPrevBtn" id="team-circle-left">
									<i className="lni-chevron-left" />
								</a>
								<a className="circle ini-customNextBtn" id="team-circle-right">
									<i className="lni-chevron-right" />
								</a> */}
							</div>
						</div>
					</div>
				</section>
				{/* START ABOUT */}
				<section className="section slide2 about" id="about">
					<div className="bg-overlay bg-pink opacity-8" />
					<div className="container expand-container">
						<div
							className="col-12 col-md-12 px-0 section-heading about-fadeIn wow"
							data-wow-delay=".5s"
						>
							<h2 className="main-font">
								A platform for getting things done in the enterprise.{" "}
							</h2>
							<span className="d-block">
								{" "}
								Reimagine enterprise processes with AI, move beyond rigid
								programming frameworks. Operationalize existing departmental
								databases and spreadsheets.
							</span>{" "}
						</div>
						<div className="row pb-5 mt-5">
							{/* Feature Box */}
							<div
								className="col-12 col-md-3 about-zoom1In wow"
								data-wow-delay=".2s"
							>
								<div className="feature-item text-center text-md-left">
									{/* <i className="las la-laptop f-icon" /> */}
									<span>
										<img
											src={workFlow}
											alt="work-flow"
											className="slide-2Img"
										/>
									</span>
									<h5 className="title main-font mt-1">Workflow</h5>
									<p className="alt-font mt-3">
										Use existing templates from HR, Accounting, Marketing,
										Sales, Operations and many more. Design new workflows using
										AI.
									</p>
								</div>
							</div>
							{/* Feature Box */}
							<div
								className="col-12 col-md-3 about-zoom2In wow"
								data-wow-delay=".4s"
							>
								<div className="feature-item text-center text-md-left">
									{/* <i className="las la-pen-fancy f-icon" /> */}
									<span>
										<img src={dataImg} alt="work-flow" className="slide-2Img" />
									</span>
									<h5 className="title main-font mt-1">Data</h5>
									<p className="alt-font mt-3">
										Utilize existing structured and unstructured data - bring
										your spreadsheets to life. Build apps on top of your data
										with automatically generated APIs!
									</p>
								</div>
							</div>
							{/* Feature Box */}
							<div
								className="col-12 col-md-3 about-zoom3In wow"
								data-wow-delay=".6s"
							>
								<div className="feature-item text-center text-md-left">
									{/* <i className="las la-digital-tachograph f-icon" /> */}
									<span>
										<img src={ai} alt="work-flow" className="slide-2Img" />
									</span>
									<h5 className="title main-font mt-1">AI</h5>
									<p className="alt-font mt-3">
										Design workflows using plain English! Streamline paper based
										processes with the Methodical Document AI. Search and ask
										questions of your documents!
									</p>
								</div>
							</div>
							{/* Feature Box */}
							<div
								className="col-12 col-md-3 about-zoom4In wow"
								data-wow-delay=".8s"
							>
								<div className="feature-item text-center text-md-left">
									<span>
										<img
											src={integration}
											alt="work-flow"
											className="slide-2Img"
										/>
									</span>
									{/* <i className="las la-comment f-icon" /> */}
									<h5 className="title main-font mt-1">Integrations</h5>
									<p className="alt-font mt-3">
										Extend business processes and integrate with 3rd party
										systems including Microsoft 365, the Google Suite, SAP,
										Xero, Zoho and many more to connect all your teams.
									</p>
								</div>
							</div>
						</div>
						<div
							className="box position-relative about-zoom5In wow"
							data-wow-delay=".5s"
						>
							<div className="box-overlay" />
							<div className="row align-items-center">
								<div className="col-12 col-md-7 col-lg-9">
									<h2 className="main-font">Start using Methodical for free</h2>
									<p className="py-4 py-md-0">
										Access all Methodical features for 30 days, then decide
										which plan best suits your business or compare plans.{" "}
									</p>
								</div>
								<div className="col-12 col-md-5 col-lg-3 text-center">
									<a
										href="#contact"
										className="btn btn-medium btn-rounded text-capitalize mb-4 mb-md-0 btn-reqDemo"
									>
										Request Demo
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* END ABOUT */}
				{/* START BLOG */}
				<section className="section slide6 blog-bg blog" id="blog">
					<div className="bg-overlay bg-blue opacity-8" />
					<div className="container expand-container pr-md-0">
						<div className="row align-items-center section-heading">
							<div
								className="col-12  main-heading section4left wow"
								data-wow-delay=".8s"
							>
								<h2 className="main-font">
									Workflows - there's a template for that!
								</h2>
								<span className="d-block">
									{" "}
									Build workflows from scratch, using plain English, or choose
									an existing template from a wide assortment of templates.
								</span>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-4 mb-4 mb-md-0">
								<div id="accordion" role="tablist" className="accordion">
									{renderCard(0, "HR", HRImg)}
									{renderCard(1, "Marketing", marketingImg)}
									{renderCard(2, "Sales", salesImg)}{" "}
									{/* Update with appropriate image */}
									{renderCard(3, "Accounts", accountImg)}{" "}
									{/* Update with appropriate image */}
									{renderCard(4, "Operations", operations)}{" "}
									{renderCard(5, "IT & Software", IT)}{" "}
									{/* Update with appropriate image */}
								</div>
							</div>
							<div
								className="col-12 col-md-8 section4Img wow"
								data-wow-delay=".2s"
							>
								<img src={slide3Img} alt="" className="workflow-img " />
							</div>
						</div>
					</div>
				</section>

				{/* START slide 5 */}
				<section
					className="section slide4 portfolio-bg portfolio"
					id="portfolio"
				>
					<div className="bg-overlay text-light-black opacity-8" />
					<div className="container">
						<div className="row align-items-center section-heading">
							<div
								className="col-12 col-md-12 main-heading section5left wow"
								data-wow-delay=".8s"
							>
								<h2 className="main-font">
									Wire up workflows with data{" "}
									<span className="d-block mt-3">
										Model your data using AI or just simply upload your existing
										spreadsheets to build custom apps.
									</span>
								</h2>
							</div>
						</div>
						<div className="row pb-5 mt-5">
							{/* Feature Box */}
							<div
								className="col-12 col-md-3 workflow-zoom1In wow"
								data-wow-delay=".2s"
							>
								<div className="feature-item text-center text-md-left">
									{/* <i className="las la-laptop f-icon" /> */}
									<span>
										<img
											src={dataModels}
											alt="work-flow"
											className="slide-2Img"
										/>
									</span>
									<h5 className="title main-font mt-1">Data Models</h5>
									<p className="alt-font mt-3">
										Design custom data models manually or using AI tools to go
										with your workflow models.
									</p>
								</div>
							</div>
							{/* Feature Box */}
							<div
								className="col-12 col-md-3 workflow-zoom2In wow"
								data-wow-delay=".4s"
							>
								<div className="feature-item text-center text-md-left">
									{/* <i className="las la-pen-fancy f-icon" /> */}
									<span>
										<img
											src={spreadSheets}
											alt="work-flow"
											className="slide-2Img"
										/>
									</span>
									<h5 className="title main-font mt-1">Spreadsheets</h5>
									<p className="alt-font mt-3">
										Upload spreadsheets to automatically create data models
										based on your spreadsheet data!
									</p>
								</div>
							</div>
							{/* Feature Box */}
							<div
								className="col-12 col-md-3 workflow-zoom3In wow"
								data-wow-delay=".6s"
							>
								<div className="feature-item text-center text-md-left">
									{/* <i className="las la-digital-tachograph f-icon" /> */}
									<span>
										<img src={dataApi} alt="work-flow" className="slide-2Img" />
									</span>
									<h5 className="title main-font mt-1">Data APIs</h5>
									<p className="alt-font mt-3">
										Methodical APIs give you full control over your data, with
										change capture.
									</p>
								</div>
							</div>
							{/* Feature Box */}
							<div
								className="col-12 col-md-3 workflow-zoom4In wow"
								data-wow-delay=".8s"
							>
								<div className="feature-item text-center text-md-left">
									<span>
										<img
											src={analytics}
											alt="work-flow"
											className="slide-2Img"
										/>
									</span>
									{/* <i className="las la-comment f-icon" /> */}
									<h5 className="title main-font mt-1">Analytics</h5>
									<p className="alt-font mt-3">
										Design dashboards and reports for your Methodical apps using
										AI in minutes.
									</p>
								</div>
							</div>
						</div>
						<div
							className="box position-relative workflow-fadeIn wow"
							data-wow-delay=".5s"
						>
							<div className="box-overlay" />
							<div className="row align-items-center">
								<div className="col-12 col-md-7 col-lg-9">
									<h2 className="main-font">Explore Methodical Features</h2>
									<p className="py-4 py-md-0">
										Schedule a demo with us and explore Methodical's data
										modelling and app development capabilities.{" "}
									</p>
								</div>
								<div className="col-12 col-md-5 col-lg-3 text-center">
									<a
										href="#contact"
										className="btn btn-medium btn-rounded text-capitalize mb-4 mb-md-0 btn-reqDemo"
									>
										Request Demo
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* END slide 5 */}
				{/* slide 6*/}
				<section className="section slide3 team" id="team">
					<div
						className="bg-overlay opacity-9"
						style={{ background: "#8c6c09" }}
					/>
					<div className="container expand-container">
						<div
							className="col-12 px-0 section-heading team-fadeIn wow"
							data-wow-delay=".5s"
						>
							<h2 className="main-font">
								AI - Generative AI to build Workflows and Apps.{" "}
								<span className="d-block mt-3">
									{" "}
									Design Workflows and Apps with a full data model and business
									logic using our Generative AI. Cut time to market for your
									Bring your teams together and get work done. Today. enterprise
									apps.
								</span>
							</h2>
						</div>
						<div className="row pb-5 mt-5">
							<div
								className="col-12 col-md-4 team-zoom1In wow"
								data-wow-delay=".2s"
							>
								<div className="feature-item text-center text-md-left">
									<span>
										<img
											src={workFlow}
											alt="work-flow"
											className="slide-2Img"
										/>
									</span>
									<h5 className="title main-font mt-1">
										Workflow & App Designer
									</h5>
									<p className="alt-font mt-3">
										Design workflows and apps using plain English using our
										Generative AI. Get up and running with Methodical apps in no
										time.
									</p>
								</div>
							</div>
							<div
								className="col-12 col-md-4 team-zoom2In wow"
								data-wow-delay=".4s"
							>
								<div className="feature-item text-center text-md-left">
									<span>
										<img src={ai} alt="work-flow" className="slide-2Img" />
									</span>
									<h5 className="title main-font mt-1">Document AI</h5>
									<p className="alt-font mt-3">
										Automate your paper based processes by using our Document AI
										- extract content from PDFs and images and have Methodical
										perform routine manual steps automatically. Improve
										efficiency and super charge your teams.
									</p>
								</div>
							</div>
							<div
								className="col-12 col-md-4 team-zoom3In wow"
								data-wow-delay=".6s"
							>
								<div className="feature-item text-center text-md-left">
									<span>
										<img
											src={searchIcon}
											alt="work-flow"
											className="slide-2Img"
										/>
									</span>
									<h5 className="title main-font mt-1">Search</h5>
									<p className="alt-font mt-3">
										Search enterprise documents on Methodical and ask questions
										of them to get pertinent answers from unstructured document
										data.
									</p>
								</div>
							</div>
						</div>
						<div
							className="box position-relative section6fadeIn wow"
							data-wow-delay=".5s"
						>
							<div className="box-overlay" />
							<div className="row align-items-center">
								<div className="col-12 col-md-7 col-lg-9">
									<h2 className="main-font">Start using Methodical for free</h2>
									<p className="py-4 py-md-0">
										Access all Methodical features for 30 days, then decide
										which plan best suits your business or compare plans.{" "}
									</p>
								</div>
								<div className="col-12 col-md-5 col-lg-3 text-center">
									<a
										href="#contact"
										className="btn btn-medium btn-rounded text-capitalize mb-4 mb-md-0 btn-reqDemo"
									>
										Request Demo
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* END slide 6 */}
				{/* Integrations */}
				<section className="section slide7 integration" id="integrations">
					<div
						className="bg-overlay opacity-8"
						style={{ background: "rgb(0 88 91)" }}
					></div>
					<div className="container expand-container">
						<div className="row align-items-center mb-3 mb-md-5 section-heading">
							<div
								className="col-12 col-md-12 section7left wow"
								data-wow-delay=".8s"
							>
								<h2 className="main-font">
									Integrations - Methodical + Enterprise systems.{" "}
								</h2>
								<span className="d-block">
									Integrating Methodical workflows with Enterprise systems is a
									snap! Methodical provides an easy, low cost alternative to
									implement a workflow + applications layer on top of Enterprise
									systems.
								</span>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h5 className=" text-white mb-3">
									Our current integration library:
								</h5>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-12 d-flex align-items-center justify-content-between libraries">
								<div
									className=" integration-zoom1In wow text-dark "
									data-wow-delay=".2s"
								>
									{/* <div className="card-img-top card-img-container"> */}
									<img
										src={m365}
										alt="Microsoft 365"
										width={200}
										height="200px"
									/>

									{/* </div> */}

									{/* <div className="card-body">
										<strong className="card-title">Microsoft 365</strong>
									</div> */}
								</div>
								<div
									className=" integration-zoom2In wow text-dark "
									data-wow-delay=".2s"
								>
									<img
										src={gSuite}
										alt="Microsoft 365"
										width={200}
										height="200px"
									/>
								</div>
								<div
									className=" integration-zoom3In wow  text-dark "
									data-wow-delay=".2s"
								>
									<img
										src={xero}
										alt="Microsoft 365"
										width={200}
										height="200px"
									/>
								</div>
								<div
									className=" integration-zoom4In wow  text-dark "
									data-wow-delay=".2s"
								>
									<img
										src={zoho}
										alt="Microsoft 365"
										width={200}
										height="200px"
									/>
								</div>
							</div>
							{/* <div className="col-12 col-md-3">
								<div
									className=" integration-zoom2In wow card text-dark "
									data-wow-delay=".4s"
								>
									<div className="card-img-top card-img-container">
										<img src={gSuite} alt="Microsoft 365" height="200px" />
									</div>
									<div className="card-body">
										<strong className="card-title">G Suite</strong>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-3">
								<div
									className=" integration-zoom3In wow card text-dark "
									data-wow-delay=".6s"
								>
									<div className="card-img-top card-img-container">
										<img src={xero} alt="Microsoft 365" height="200px" />
									</div>
									<div className="card-body">
										<strong className="card-title">Xero</strong>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-3">
								<div
									className=" integration-zoom4In wow card text-dark "
									data-wow-delay=".8s"
								>
									<div className="card-img-top card-img-container">
										<img src={zoho} alt="Microsoft 365" height="200px" />
									</div>
									<div className="card-body">
										<strong className="card-title">Zoho</strong>
									</div>
								</div>
							</div> */}
						</div>
						<div className="row ">
							<div className="col-12">
								<h5
									className=" text-white mt-5 section7left wow"
									data-wow-delay=".8s"
								>
									We're expanding our integration library every day.
								</h5>
							</div>
						</div>
					</div>
				</section>
				{/* End Integrations */}
				{/* START CONTACT */}
				<section className="section slide5 contact-sec" id="contact">
					<div
						className="bg-overlay opacity-9"
						style={{ background: "#5F093D" }}
					/>
					<div className="container expand-container">
						<div className="row">
							<div
								className="col-12 col-lg-7 section8left wow "
								data-wow-delay=".8s"
							>
								<h4 className="heading text-center text-md-left main-font">
									Request a Demo
									<span className="d-block mt-2">Let's Get In Touch</span>
								</h4>
								<form
									className="row contact-form wow fadeInLeft"
									id="contact-form-data"
								>
									<div className="col-12 col-lg-8" id="result" />
									<div className="col-12 col-lg-8">
										<input
											type="text"
											name="userName"
											placeholder="Name"
											className="form-control"
											value={data.name}
											onChange={(e) => handleChange(e)}
										/>
										<input
											type="text"
											name="userPhone"
											placeholder="Contact No"
											className="form-control"
											value={data.phone}
											onChange={(e) => handleChange(e)}
										/>
										<input
											type="email"
											name="userEmail"
											placeholder="Work Email"
											className="form-control"
											value={data.email}
											onChange={(e) => handleChange(e)}
										/>
										<textarea
											className="form-control"
											name="userMessage"
											rows={6}
											placeholder="Type Your Message Here"
											defaultValue={""}
											value={data.message}
											onChange={(e) => handleChange(e)}
										/>
										<span className="mb-3 text-danger">{error}</span>
										<a
											href="javascript:void(0);"
											className="btn btn-medium btn-rounded btn-white rounded-pill w-100 contact_btn main-font"
											onClick={handleSubmit}
										>
											Submit Information
										</a>
									</div>
								</form>
							</div>
							<div
								className="col-12 col-lg-5 section8Right wow text-center text-lg-left d-flex align-items-start flex-column "
								data-wow-delay=".8s"
							>
								<div className="contact-details wow fadeInRight">
									<h4 className="heading main-font">Office Location</h4>
									<p className="text alt-font">
										We are based in Chennai, India but with a team distributed
										all over India.
									</p>
									<ul>
										<li>
											<i aria-hidden="true" className="fas fa-map-marker-alt" />{" "}
											#8 Lalitha Nagar 1st Street, <br />
											Papanasam Sivan Road, Santhome,
											<br /> Chennai 600004, India.{" "}
										</li>
										<li>
											<i aria-hidden="true" className="fas fa-phone-volume" />
											<span>+91-98400-44665</span>
										</li>
										<li>
											<i aria-hidden="true" className="fas fa-paper-plane" />
											hello@methodical.ai
										</li>
										<li>
											<i aria-hidden="true" className="fas fa-globe" />
											www.methodical.ai
										</li>
									</ul>
								</div>
								<div className="slider-bottom cursor-light">
									<div className="slider-social side-icons cursor-light">
										<ul className="list-unstyled">
											<li className="animated-wrap">
												<a
													className="animated-element"
													href="https://www.facebook.com/people/Methodical/100083835514354/"
													target="_blank"
												>
													<i className="fab fa-facebook-f" />
												</a>
											</li>
											{/* <li className="animated-wrap">
												<a
													className="animated-element"
													href="https://twitter.com/rbt_tech?lang=en"
													target="_blank"
												>
													<i className="fab fa-twitter" />
												</a>
											</li> */}
											<li className="animated-wrap">
												<a
													className="animated-element"
													href="https://www.linkedin.com/company/methodical-ai/"
													target="_blank"
												>
													<i className="fab fa-linkedin-in" />
												</a>
											</li>
											{/* <li className="animated-wrap">
												<a
													className="animated-element"
													href="https://instagram.com/rbt_tech?igshid=OGQ5ZDc2ODk2ZA=="
													target="_blank"
												>
													<i className="fab fa-instagram" />
												</a>
											</li> */}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<ToastContainer
						position="top-center"
						autoClose={5000}
						closeOnClick
						theme="colored"
					/>
				</section>
				<section className="section slide8 footer" id="footer">
					<div
						className="bg-overlay opacity-9"
						style={{ background: "#041F60" }}
					/>
					<div className="container expand-container ">
						<div className="row align-items-center mb-3 mb-md-5 section-heading">
							<div
								className="col-12 col-md-12 section9left wow px-2"
								data-wow-delay=".8s"
							>
								<h2 className="main-font">
									One platform for all your workflows.{" "}
								</h2>
								<span className="d-block">
									Join a growing community of Methodical users.
								</span>
							</div>
						</div>
						<div className="row ">
							<div className="col-12">
								<h5 className="main-font text-white mt-5">Useful Links</h5>
							</div>
						</div>
						<Footer />
						<div
							className="box position-relative footer-zoom6In wow"
							data-wow-delay=".5s"
						>
							<div className="box-overlay" />
							<div className="row align-items-center">
								<div className="col-12 col-md-7 col-lg-9">
									<h2 className="main-font">Start using Methodical for free</h2>
									<p className="py-4 py-md-0">
										Access all Methodical features for 30 days, then decide
										which plan best suits your business or compare plans.{" "}
									</p>
								</div>
								<div className="col-12 col-md-5 col-lg-3 text-center">
									<a
										href="#contact"
										className="btn btn-medium btn-rounded text-capitalize mb-4 mb-md-0 btn-reqDemo"
									>
										Request Demo
									</a>
								</div>
							</div>
						</div>
						<div className="slider-copyright text-white link">
							<a
								href="/privacyPolicy"
								target="_blank"
								className="mr-4 privacy-link"
							>
								Privacy Policy
							</a>
							<span>© RedBlackTree Technologies Pvt. Ltd.</span>
						</div>
					</div>
				</section>
				{/* END CONTACT */}
			</div>
			{/* Start Copyright */}
			<div className="slider-bottom ">
				<div className="slider-header left slider-social container p-0">
					<ul className="list-unstyled">
						<li className="mr-5">
							<a className="logo-header d-flex ">
								<img src={Logo} alt="Logo" width={40} height={40} />
								Methodical
							</a>
						</li>
						<li className="d-none">
							<a href="javascript:void(0);">Features</a>
						</li>
						<li className="d-none">
							<a href="/pricing">Pricing</a>
						</li>
						<li className="d-none">
							<a href="javascript:void(0);">About Us</a>
						</li>
					</ul>
					<ul className="list-unstyled right">
						<li>
							<a
								href="https://app.methodical.ai/login"
								style={{ margin: "0 !important" }}
							>
								Sign In
							</a>
						</li>
					</ul>
				</div>

				<div className="slider-copyright text-white link">
					<a
						href="/privacyPolicy"
						target="_blank"
						className="mr-4 privacy-link"
					>
						Privacy Policy
					</a>
					<span>© RedBlackTree Technologies Pvt. Ltd.</span>
				</div>
			</div>
			{/* End Copyright */}
			{/*Animated Cursor*/}
			{/* <div id="animated-cursor">
				<div id="cursor">
					<div id="cursor-loader" />
				</div>
			</div> */}
		</div>
	);
};

export default ParallaxComponent;
