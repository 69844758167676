import React, { useEffect, useState } from "react";
import "./header.css";
import Logo from "../../images/methodical-logo.svg";
import $ from "jquery";

import { Link } from "react-router-dom";
const Header = (props) => {
	const [isMobNavExpanded, setIsMobNavExpanded] = useState(false);

	const mobileClick = () => {
		setIsMobNavExpanded(!isMobNavExpanded);
	};
	useEffect(() => {
		$(".side-nav-menu .nav-menu li a").on("click", function () {
			// $(".side_nav").removeClass("expand_nav");
			// $("#close_nav").removeClass("close_nav");
			// $(".side_nav").removeClass("expand_nav");
			// $("#my_tog").removeClass("close_nav");
			// $("#close_nav").attr("id", "my_tog");
			$("#pp-nav").show();
			// $(".side-nav-menu .nav-menu .nav-item .nav-link").removeClass("active");
			$(this).addClass("active");
		});
		if (isMobNavExpanded) {
			console.log("on;cick");

			$(".broad").addClass("broad-nav");
			$(".broad").css({ opacity: "1" });
			$(".head-nav").hide();
			$("body").addClass("show-modal");
			$("nav").addClass("w-100");
		} else {
			console.log("broad click");

			$(".broad").css({ opacity: "0" });
			$("body").removeClass("show-modal");
			setTimeout(function () {
				$(".broad").removeClass("broad-nav");
			}, 100);
		}
	}, [isMobNavExpanded]);

	$(window).on("scroll", function () {
		if ($(window).width() <= 767) {
			$("#home").addClass("fixed-top");
			$("#home").addClass("fix-top");
			$(".broad").css("background-color", "white");
			$(".navbar").css("background-color", "white");
		}
	});
	return (
		<>
			<header id="custom_header">
				<nav>
					<div className="headerNav container">
						<div className="logo">
							<Link to="/">
								<div className="logo-header">
									<img src={Logo} alt="Logo" width={40} height={40} />
									<h4>Methodical</h4>
								</div>
							</Link>
						</div>

						<div className="slider-header left slider-social  p-2 d-flex justify-content-end align-items-center flex-fill">
							{/* <ul className="list-unstyled">
								<li>
									<a href="javascript:void(0);">Features</a>
								</li>
								<li>
									<a href="/pricing">Pricing</a>
								</li>
								<li>
									<a href="javascript:void(0);">About Us</a>
								</li>
							</ul> */}
							<ul className="list-unstyled right align-self-end">
								<li>
									<a
										href="https://app.methodical.ai/login"
										style={{ margin: "0 !important" }}
									>
										Sign In
									</a>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</header>
			<header id="home">
				<nav className={"navbar navbar-light cursor-light d-md-none"}>
					<div className="my_nav_tog d-block d-md-none" onClick={mobileClick}>
						<span />
						<span />
						<span />
					</div>
					<a className="navbar-brand ml-auto" href="/">
						<img src={Logo} alt="Logo" style={{ width: "30px" }} />
					</a>
				</nav>
				<div className="broad">
					<span
						className="btn-close"
						id="btn_sideNavClose"
						onClick={mobileClick}
					>
						<i />
						<i />
					</span>
					<nav className="side-nav-menu">
						<a className="brand-logo" href="/">
							<img src={Logo} alt="Logo" style={{ width: "50px" }} />
						</a>
						<ul className="nav-menu nav-fill">
							{/* <li className="nav-item">
								<a className="nav-link" href="/">
									Features
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/pricing">
									Pricing
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/">
									About Us
								</a>
							</li> */}
							<li className="nav-item">
								<a className="nav-link" href="https://app.methodical.ai/login">
									Sign In
								</a>
							</li>
						</ul>
						<div className="social-icons">
							<span>
								<a href="javascript:void(0);">
									<i className="fab fa-facebook-f" />
								</a>
							</span>
							<span>
								<a href="javascript:void(0);">
									<i className="fab fa-twitter" />
								</a>
							</span>
							<span>
								<a href="javascript:void(0);">
									<i className="fab fa-linkedin-in" />
								</a>
							</span>
							<span>
								<a href="javascript:void(0);">
									<i className="fab fa-instagram" />
								</a>
							</span>
						</div>
						<p className="rites-res">
							<a href="/privacyPolicy" target="_blank" className="mr-4">
								Privacy Policy
							</a>
							<span style={{ color: "#000000", fontSize: "14px" }}>
								© RedBlackTree Technologies Pvt. Ltd.
							</span>
						</p>
					</nav>
				</div>
			</header>
		</>
	);
};

export default Header;
