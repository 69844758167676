import React from "react";
import "./main.css";
import Header from "../Header/Header";
import { Footer } from "../Parallax/footer";
import $ from "jquery";

const PrivacyPolicy = (props) => {
	$(window).on("scroll", function () {
		console.log($(this).scrollTop());
		if ($(window).width() > 767) {
			if ($(this).scrollTop() > 7200) {
				// Set position from top to add class
				$("#custom_header").addClass("bg-transparent");
				$(".headerNav h4").addClass("text-white");
				$(".slider-social a").addClass("text-white");
			} else {
				$("#custom_header").removeClass("bg-transparent");
				$(".headerNav h4").removeClass("text-white");
				$(".slider-social a").removeClass("text-white");
			}
		} else if ($(window).width() <= 767) {
			if ($(this).scrollTop() > 12960) {
				$(".broad").css("background-color", "#041F60");
				$(".navbar").css("background-color", "#041F60");
				$(".my_nav_tog span").css("background-color", "white");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: white; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: white; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "white");
				$(".social-icons span a").css("color", "white");
				$(".broad .rites-res span").css("color", "white");
			} else {
				$(".broad").css("background-color", "white");
				$(".navbar").css("background-color", "white");
				$(".my_nav_tog span").css("background-color", "black");
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::after { background: black; } </style>"
				);
				$(".broad .btn-close").append(
					"<style> .broad .btn-close::before { background: black; } </style>"
				);
				$(".broad .side-nav-menu ul li a").css("color", "black");
				$(".social-icons span a").css("color", "black");
				$(".broad .rites-res span").css("color", "black");
			}
		}
	});
	return (
		<div>
			<Header />
			<div
				className="page-component__bg_image_box bg-white-color"
				id="text-02-143231"
			>
				<div className="page-component__bg_overlay_box" />
				<div
					className="page-component__wrapper pt-2 pt-md-5"
					style={{ zIndex: 10, paddingTop: 75, paddingBottom: 30 }}
				>
					<section>
						<div className="text--02">
							<div className="container container--mid">
								<div className="text--02__box">
									<div className="text--02__content_box text--02__content_box--bottom" />
									<div className="text--02__content_box text--02__content_box--top">
										<h3>Effective date: July 1, 2021</h3>
										<div className="content_box">
											<div>
												<h3>
													<strong>Last updated on: February 21, 2022</strong>
												</h3>
												<p>
													<br />
													<strong>1. GENERAL</strong>
													&nbsp;
												</p>
												<p>
													a. This Website with the URL of www.methodical.ai is
													operated by RedBlackTree Technologies Pvt. Ltd. We are
													committed to protecting and respecting your privacy.
													We do collect your personal information and process
													your personal data in accordance with the IT Act, 2000
													(21 of 2000) and other national and state laws which
													relate the processing of personal data. Please read
													the following carefully to understand our views and
													practices regarding your personal data.&nbsp;
												</p>
												<p>
													b. We collect your personal information in order to
													provide and continually improve our products and
													services.&nbsp;
												</p>
												<p>
													c. Our privacy policy is subject to change at any time
													without notice. To make sure you are aware of any
													changes, please review this policy periodically.&nbsp;
												</p>
												<p>
													d. All partner firms and any third-party working with
													or for Us, and who have access to personal
													information, will be expected to read and comply with
													this policy.&nbsp;
												</p>
												<p>
													<br />
													<strong>2. HOW WE COLLECT THE INFORMATION</strong>
													&nbsp;
												</p>
												<p>
													a. From you directly and through this Site: We may
													collect information through the Website when you
													visit. The data we collect depends on the context of
													your interactions with our Website.&nbsp;
												</p>
												<p>
													b. Through business interaction: We may collect
													information through business interaction with you or
													your employees.&nbsp;
												</p>
												<p>
													c. From other sources: We may receive information from
													other sources, such as public databases; joint
													marketing partners; social media platforms; or other
													third parties such as:&nbsp;
												</p>
												<p>
													I. Updated delivery and address information from our
													carriers or other third parties, which we use to
													correct our records and deliver your next purchase or
													communication more easily.&nbsp;
												</p>
												<p>
													II. Information about your interactions with the
													products and services offered by our
													subsidiaries.&nbsp;
												</p>
												<p>
													<br />
													<strong>3. INFORMATION WE COLLECT</strong>
													&nbsp;
												</p>
												<p>
													a. We collect information primarily to provide better
													services to all of our customers.&nbsp;
												</p>
												<p>
													b. We collect the following information from you when
													you use or signup on our Website:&nbsp;
												</p>
												<p>
													Name, corporate email, designation, department, phone
													number, profile picture&nbsp;
												</p>
												<p>
													c. When you visit our Site, some information is
													automatically collected. This may include information
													such as the Operating Systems (OS) running on your
													device, Internet Protocol (IP) address, access times,
													browser type, and language, and the website you
													visited before our Site. We also collect information
													about how you use Our products or services.&nbsp;
												</p>
												<p>
													d. We automatically collect purchase or content use
													history, which we sometimes aggregate with similar
													information from other customers to create features
													such as Best Seller, Top Rated, etc...&nbsp;
												</p>
												<p>
													e. The full Uniform Resource Locators (URL)
													clickstream to, through and from our website
													(including date and time); cookie number; products
													and/or content you viewed or searched for; page
													response times; download errors; length of visits to
													certain pages; page interaction information (such as
													scrolling, clicks, and mouse-overs).&nbsp;
												</p>
												<p>
													f. We automatically collect information using
													"Cookies". Cookies are small data files stored on your
													hard drive the websites. Among other things, cookies
													help us improve our Site, our marketing activities,
													and your experience. We use cookies to see which areas
													and features are popular and to count visits to our
													Site.&nbsp;
												</p>
												<p>
													g. Most Web browsers are set to accept cookies by
													default. If you prefer, you can choose to set your
													browser to remove cookies and to reject cookies. If
													you set your browser to reject cookies, some features
													will be unavailable. For more information on how to
													reject cookies, see your browser's instructions on
													changing your cookie settings.&nbsp;
												</p>
												<p>
													h. We will retain your information as long as we
													require this to provide you with the goods and
													services and for such period as mandated by the
													concerned laws.&nbsp;
												</p>
												<p>
													i. If you opt to receive marketing correspondence from
													us, subscribe to our mailing list or newsletters,
													enter into any of our competitions or provide us with
													your details at networking events, we may use your
													personal data for our legitimate interests in order to
													provide you with details about our goods, services,
													business updates and events.&nbsp;
												</p>
												<p>
													<strong>4. HOW WE USE INFORMATION</strong>
													&nbsp;
												</p>
												<p>
													a. We use the information we collect primarily to
													provide, maintain, protect and improve our current
													products and services.&nbsp;
												</p>
												<p>
													b. We use the information collected through this
													website as described in this policy and we may use
													your information to:&nbsp;
												</p>
												<p>
													I. Improve our services, Site and how we operate our
													businesses;&nbsp;
												</p>
												<p>
													II. Understand and enhance your experience using our
													Site, products and services;&nbsp;
												</p>
												<p>
													III. Personalize our products or services and make
													recommendations;&nbsp;
												</p>
												<p>
													IV. Provide and deliver products and services you
													request;&nbsp;
												</p>
												<p>
													V. Process, manage, complete, and account for
													transactions;&nbsp;
												</p>
												<p>
													VI. Provide customer support and respond to your
													requests, comments, and inquiries;&nbsp;
												</p>
												<p>
													VII. Create and manage the online accounts you manage
													on our Website;&nbsp;
												</p>
												<p>
													VIII. Send you related information, including
													confirmations, invoices, technical notices, updates,
													security alerts and support and administrative
													messages;&nbsp;
												</p>
												<p>
													IX. Communicate with you about promotions, upcoming
													events and news about products and services;&nbsp;
												</p>
												<p>
													X. We may process your personal information without
													your knowledge or consent where required by applicable
													law or regulation for the purposes of verification of
													identity or for prevention, detection or
													investigation, including of cyber incidents,
													prosecution and punishment of offences;&nbsp;
												</p>
												<p>
													XI. Protect, investigate and deter against fraudulent,
													unauthorized or illegal activity.&nbsp;
												</p>
												<p>
													<br />
													<strong>5. DATA TRANSFER</strong>
													&nbsp;
												</p>
												<p>
													a. Information about our user is an important part of
													our business and we take due care to protect the
													same.&nbsp;
												</p>
												<p>
													b. We share your data with your consent or to complete
													any transaction or provide any product or service you
													have requested or authorized. We also share data with
													our affiliates and subsidiaries, with vendors working
													on our behalf.&nbsp;
												</p>
												<p>
													c. We may employ other companies and individuals to
													perform functions on our behalf. The functions include
													fulfilling orders for products or services, delivering
													packages, sending postal mail and e-mail, removing
													repetitive information from customer lists, providing
													marketing assistance, providing search results and
													links (including paid listings and links), processing
													payments, transmitting content, scoring credit risk,
													and providing customer service.&nbsp;
												</p>
												<p>
													d. These third-party service providers have access to
													personal information needed to perform their functions
													but may not use it for other purposes. Further, they
													must process the personal information in accordance
													with this Privacy Policy and as permitted by
													applicable data protection laws.&nbsp;
												</p>
												<p>
													e. We release account and other personal information
													when we believe is appropriate to comply with the law,
													enforce or apply our conditions of use, and other
													agreements, protect the rights, property or safety of
													Us, our users or others. This includes exchanging
													information with other companies and organizations for
													fraud protection and credit risk reduction.&nbsp;
												</p>
												<p>
													<br />
													<strong>6. COOKIES</strong>
													&nbsp;
												</p>
												<p>
													a. To optimize our web presence, we use cookies. These
													are small text files stored on your computer's main
													memory. These cookies are deleted after you close the
													browser. Other cookies remain on your computer
													(long-term cookies) and permit its recognition on your
													next visit. This allows us to improve your access to
													our site. This helps us to learn more about your
													interests, and provide you with essential features and
													services and for additional purposes, including:&nbsp;
												</p>
												<p>
													I. Keeping track of items stored in your shopping
													basket.&nbsp;
												</p>
												<p>
													II. Conducting research and diagnostics to improve the
													content, products, and services.&nbsp;
												</p>
												<p>III. Preventing fraudulent activity.&nbsp;</p>
												<p>IV. Improving security.&nbsp;</p>
												<p>
													b. Our cookies allow you to take advantage of some of
													our essential features. For instance, if you block or
													otherwise reject our cookies, you will not be able to
													add items to your shopping basket, proceed to
													checkout, or use any products or services that require
													you to sign in.&nbsp;
												</p>
												<p>
													c. Approved third parties also may set cookies when
													you interact with Our services.&nbsp;
												</p>
												<p>
													d. Third parties include search engines, providers of
													measurement and analytics services, social media
													networks, and advertising companies.&nbsp;
												</p>
												<p>
													e. Third parties use cookies in the process of
													delivering content, including ads relevant to your
													interests, to measure the effectiveness of their ads,
													and to perform services on behalf of Us.&nbsp;
												</p>
												<p>
													f. You can prevent the storage of cookies by choosing
													a "disable cookies" option in your browser settings.
													But this can limit the functionality of our
													services.&nbsp;
												</p>
												<p>
													<br />
													<strong>7. DATA SECURITY</strong>
													&nbsp;
												</p>
												<p>
													a. We take due care to protect customer data.
													Technical measures are in place to prevent
													unauthorized or unlawful access to data and against
													accidental loss or destruction of, or damage to, data.
													The employees who are dealing with the data have been
													trained to protect the data from any illegal or
													unauthorized usage.&nbsp;
												</p>
												<p>
													b. We work to protect the security of your information
													during transmission by using Secure Sockets Locker
													(SSL) software, which encrypts information you input.
													SSL allows sensitive information such as credit card
													numbers, UID's and login credentials to be transmitted
													securely.&nbsp;
												</p>
												<p>
													c. We maintain physical, electronic, and procedural
													safeguards in connection with the collection, storage,
													and disclosure of personal customer information.&nbsp;
												</p>
												<p>
													d. We take reasonable steps to help protect your
													personal information in an effort to prevent the loss,
													misuse, and unauthorized access, disclosure alteration
													and destruction. It is your responsibility to protect
													your user names and passwords to help prevent anyone
													from accessing or abusing your accounts and services.
													You should not use or reuse the same passwords you use
													with other accounts as your password for our
													services.&nbsp;
												</p>
												<p>
													e. It is important for you to protect against
													unauthorized access to your password and your
													computers, devices, and applications. Be sure to sign
													off when you finish using a shared computer.&nbsp;
												</p>
												<p>
													f. Information you provide to us is shared on our
													secure servers. We have implemented appropriate
													physical, technical and organizational measures
													designed to secure your information against accidental
													loss and unauthorized access, use, alteration or
													disclosure. In addition, we limit access to personal
													data to those employees, agents, contractors, and
													other third parties that have a legitimate business
													need for such access.&nbsp;
												</p>
												<p>
													g. Information collected from you will be stored for
													such period as required to complete the transaction
													entered into with you or such period as mandated under
													the applicable laws.&nbsp;
												</p>
												<p>
													<br />
													<strong>8. LINKS TO THIRD PARTY SITE/APPS</strong>
													&nbsp;
												</p>
												<p>
													Our Site may, from time to time, contain links to and
													from other websites of third parties. Please note that
													if you follow a link to any of these websites, such
													websites will apply different terms to the collection
													and privacy of your personal data and we do not accept
													any responsibility or liability for these policies.
													When you leave our Site, we encourage you to read the
													privacy policy of every website you visit.&nbsp;
												</p>
												<p>
													<br />
													<strong>9. SHARING OF PERSONAL INFORMATION</strong>
													&nbsp;
												</p>
												<p>
													a. We do not share your personal data with third
													parties without your prior consent other than:&nbsp;
												</p>
												<p>
													I. With third parties who work on our behalf provided
													such third parties adhere to the data protection
													principles set out in the IT Act, 2000 (21 of 2000)
													and other applicable legislation, or enter into a
													written agreement with Us requiring that the third
													party provide at least the same level of privacy
													protection as is required by such principles;&nbsp;
												</p>
												<p>
													II. To comply with laws or to respond to lawful
													requests and legal process;&nbsp;
												</p>
												<p>
													III. To protect the rights and property of Us, our
													agents, customers, and others including to enforce our
													agreements, policies and terms of use;&nbsp;
												</p>
												<p>
													IV. In an emergency, including to protect the personal
													safety of any person; and&nbsp;
												</p>
												<p>
													V. For the purpose of a business deal (or negotiation
													of a business deal) involving the sale or transfer of
													all or a part of our business or assets (business
													deals may include, for example, any merger, financing,
													acquisition, divestiture or bankruptcy transaction or
													proceeding).&nbsp;
												</p>
												<p>
													<br />
													<strong>10. CHILDREN</strong>
													&nbsp;
												</p>
												<p>
													If you are under 18, or the age of majority in the
													jurisdiction in which you reside, you may only use Our
													Website with the consent of your parent or legal
													guardian. In any case, We will not be liable for any
													cause of action that arose due to non-compliance with
													this section.&nbsp;
												</p>
												<p>
													<br />
													<strong>
														11. YOUR INFORMATION CHOICES AND CHANGES
													</strong>
													&nbsp;
												</p>
												<p>
													a. You can also make choices about the collection and
													processing of your data by Us. You can access your
													personal data and opt-out of certain services provided
													by the Us. In some cases, your ability to control and
													access to your date will be subject to applicable
													laws.&nbsp;
												</p>
												<p>
													b. You may opt-out of receiving promotional emails
													from Us by following the instructions in those emails.
													If you opt-out, we may still send you non-promotional
													emails, such as emails about our ongoing business
													relationship. You may also send requests about you got
													preferences, changes and deletions to your information
													including requests to opt-out of sharing your personal
													information with third parties by sending an email to
													the email address provided at the bottom of this
													document.&nbsp;
												</p>
												<p>
													<strong>12. CHANGES TO THIS POLICY</strong>
													&nbsp;
												</p>
												<p>
													We may change this policy from time to time. If we
													make any changes to this policy, we will change the
													"Last Updated" date above. You agree that your
													continued use of our services after such changes have
													been published to our services will constitute your
													acceptance of such revised policy.&nbsp;
												</p>
												<p>
													<br />
													<strong>13. NEWSLETTER</strong>
													&nbsp;
												</p>
												<p>
													a. Following subscription to the newsletter, your
													e-mail address is used for our advertising purposes
													until you cancel the newsletter again. Cancellation is
													possible at any time. The following consent has been
													expressly granted by you separately, or possibly in
													the course of an ordering process: (I am accepting to
													receive newsletter from this website), you may revoke
													your consent at any time with future effect. If you no
													longer want to receive the newsletter, then
													unsubscribe by clicking on unsubscribe option given in
													the email footer.&nbsp;
												</p>
												<p>
													If you have any concern about privacy or grievances
													with Us, please contact us with a thorough description
													and we will try to resolve the issue for you.&nbsp;
												</p>
												<p>
													<br />
													<strong>Contact Details:</strong>
													&nbsp;
												</p>
												<p>
													Mr. Sambhu Lakshmanan
													<br />
													Email: sambhu@redblacktree.com&nbsp;
												</p>
											</div>
										</div>
										<div className="text--02__link_box" />
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			<section className="section footer" id="p-footer">
				<div
					className="bg-overlay opacity-9"
					style={{ background: "#041F60" }}
				/>
				<div className="container expand-container ">
					<div className="row align-items-center mb-3 mb-md-5 section-heading">
						<div
							className="col-12 col-md-12 section8left wow px-2 slideInLeft"
							data-wow-delay=".8s"
						>
							<h2 className="main-font">
								One platform for all your workflows.{" "}
								<span className="d-block">
									Join a growing community of Methodical users.
								</span>
							</h2>
						</div>
					</div>
					<div className="row ">
						<div className="col-12">
							<h5 className="main-font text-white mt-5">Useful Links</h5>
						</div>
					</div>
					<Footer />
					<div
						className="box position-relative footer-zoom6In wow"
						data-wow-delay=".5s"
					>
						<div className="box-overlay" />
						<div className="row align-items-center">
							<div className="col-12 col-md-7 col-lg-9">
								<h2 className="main-font">Start using Methodical for free</h2>
								<p className="py-4 py-md-0">
									Access all Methodical features for 30 days, then decide which
									plan best suits your business or compare plans.{" "}
								</p>
							</div>
							<div className="col-12 col-md-5 col-lg-3 text-center">
								<a
									href="#home-banner"
									className="btn btn-medium btn-rounded text-capitalize mb-4 mb-md-0 getStarted"
								>
									Learn more
								</a>
							</div>
						</div>
					</div>
					<div className="slider-copyright text-white link">
						<a
							href="/privacyPolicy"
							target="_blank"
							className="mr-4 privacy-link"
						>
							Privacy Policy
						</a>
						<span>© RedBlackTree Technologies Pvt. Ltd.</span>
					</div>
				</div>
			</section>
		</div>
	);
};

export default PrivacyPolicy;
