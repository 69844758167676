import React from "react";
import linkedIn from "../../images/fb.jpg";
import fb from "../../images/linkedIn.jpg";
import Rbt from "../../images/rbt-white.png";
export const Footer = () => {
	return (
		<div className="footer-div ">
			<footer className="footer-04">
				<div className="container p-0">
					<div className="footer-04__top_wrapper text-white">
						<div
							className="footer-04__col footer-zoom1In wow zoomIn"
							data-wow-delay=".2s"
						>
							<a className="footer-04__logo_link" href="/" target="_self">
								<img
									loading="lazy"
									alt="Logo"
									className="footer-04__logo"
									src={Rbt}
									height={500}
									width={500}
								/>
							</a>
							<div className="footer-04__info content_box">
								<p>A RedBlackTree Product</p>
							</div>
						</div>
						{/* <div
							className="footer-04__col footer-zoom2In wow zoomIn"
							data-wow-delay=".4s"
						>
							<h3 className="footer-04__title">Product</h3>
							<ul className="footer-04__list">
								<li className="footer-04__item">
									<a
										href="features.html"
										target="_self"
										className="footer-04__link"
									>
										Features
									</a>
								</li>
								<li className="footer-04__item">
									<a
										href="pricing.html"
										target="_self"
										className="footer-04__link"
									>
										Pricing
									</a>
								</li>
							</ul>
						</div> */}
						<div
							className="footer-04__col footer-zoom3In wow zoomIn"
							data-wow-delay=".6s"
						>
							<a
								href="https://forms.office.com/r/VmkQeQB7Pa"
								target="_blank"
								className="footer-04__link"
							>
								<h3 className="footer-04__title">Contact us</h3>
							</a>
							<ul className="footer-04__list">
								<li className="footer-04__item">
									<a
										href="mailto:hello@methodical.ai"
										target="_self"
										className="footer-04__link"
									>
										hello@methodical.ai
									</a>
								</li>
							</ul>
						</div>
						<div
							className="footer-04__col footer-zoom3In wow zoomIn"
							data-wow-delay=".6s"
						>
							<a
								href="mailto:support@methodical.ai"
								target="_blank"
								className="footer-04__link"
							>
								<h3 className="footer-04__title">Support</h3>
							</a>
							<ul className="footer-04__list">
								<li className="footer-04__item">
									<a
										href="mailto:support@methodical.ai"
										target="_self"
										className="footer-04__link"
									>
										support@methodical.ai
									</a>
								</li>
							</ul>
						</div>
						{/* <div
							className="footer-04__col footer-zoom4In wow zoomIn"
							data-wow-delay=".8s"
						>
							<ul className="footer-04__list d-flex justify-content-between">
								<li className="footer-04__item">
									<a
										href="https://forms.office.com/r/VmkQeQB7Pa"
										target="_blank"
										className="footer-04__link"
									>
										<h4>Contact us</h4>
									</a>
								</li>
								<li className="footer-04__item">
									<a
										href="mailto:support@methodical.ai"
										target="_blank"
										className="footer-04__link"
									>
										<h3 className="footer-04__title">Support</h3>
									</a>
								</li>
							</ul>
						</div> */}
						<div
							className="footer-04__col footer-zoom5In wow zoomIn"
							data-wow-delay="1s"
						>
							<h3 className="footer-04__title">Follow Us</h3>
							<div className="footer-04__social_box">
								<div className="social-buttons social-buttons--left">
									{/* <ul className="social-buttons__list">
										<li className="social-buttons__item">
											<a
												className="social-buttons__link social-buttons__link--facebook"
												href="https://www.facebook.com/Methodical-102751655850596"
												target="_blank"
											>
												<img
													loading="lazy"
													className="social-buttons__icon"
													alt="facebook icon"
													src={fb}
												/>
											</a>
										</li>
										<li className="social-buttons__item">
											<a
												className="social-buttons__link social-buttons__link--linkedin"
												href="https://www.linkedin.com/company/methodical-ai/"
												target="_blank"
											>
												<img
													loading="lazy"
													className="social-buttons__icon"
													alt="linkedin icon"
													src={linkedIn}
												/>
											</a>
										</li>
									</ul> */}
									<div className="slider-social side-icons cursor-light">
										<ul className="list-unstyled">
											<li className="animated-wrap">
												<a
													className="animated-element"
													href="https://www.facebook.com/people/Methodical/100083835514354/"
													target="_blank"
												>
													<i className="fab fa-facebook-f" />
												</a>
											</li>
											{/* <li className="animated-wrap">
												<a
													className="animated-element"
													href="https://twitter.com/rbt_tech?lang=en"
													target="_blank"
												>
													<i className="fab fa-twitter" />
												</a>
											</li> */}
											<li className="animated-wrap">
												<a
													className="animated-element"
													href="https://www.linkedin.com/company/methodical-ai/"
													target="_blank"
												>
													<i className="fab fa-linkedin-in" />
												</a>
											</li>
											{/* <li className="animated-wrap">
												<a
													className="animated-element"
													href="https://instagram.com/rbt_tech?igshid=OGQ5ZDc2ODk2ZA=="
													target="_blank"
												>
													<i className="fab fa-instagram" />
												</a>
											</li> */}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};
